var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticStyle: {
      "max-width": "1150px",
      "margin-top": "65px"
    }
  }, [_c('main-parameters', {
    attrs: {
      "is_entity": ""
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }